
.noteEditorContainer {
  padding-top: 10px;
  width: 100%;
}

.noteEditorContent {
  min-height: 40px;
  overflow: visible;
  height: auto;
  background: white;
  padding: 0 10px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
}

.noteEditorWrapper {
  position: relative;
  margin-top: 1em;
}

.noteEditorScrollTarget {
  position: absolute;
  top: -100px;
}

.strikethroughIcon {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 0px;
  height: 24px;
  width: 24px;
}

.inlineToolbar > div {
  position: absolute;
}
.noteEditorEditing {
 min-height: 15em;
 height: auto;
 background: #fff;
 padding: 0 10px 10px;
 border: 1px solid #f1f1f1;
 border-radius: 2px;
 /* most of the window - (section heading + gps bar + bottom bar + some slop for context + size of the note controls and title) */
 max-height: calc(80vh - (40px + 34px + 40px + 40px + 0px));
 overflow: auto;
 cursor: text;
}

.noteEditor {
  width: 100%;
}

.noteEditorInactive .public-DraftEditor-content > div > div:first-of-type > .public-DraftStyleDefault-block  {
  margin-top: 0;
}

.public-DraftEditor-content > div > div:last-of-type > .public-DraftStyleDefault-block  {
  padding-bottom: 1em;
  margin-bottom: 0;
}
