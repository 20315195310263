/*
 * Copyright 2018 General Code
 */
.noteMainControls {
  flex: 0 0 auto;
  align-self: start;
  margin-top: 5px;
}

@media (max-width: 759px) {
  .responsive .noteMainControls-edit, .responsive .noteMainControls-delete {
    display: none;
  }
}
