#messageList div.message {
  margin: 10px 0;
  padding: 10px;
  width: 300px;
  border-radius: 10px;
  border: solid 1px #404040;
  background-color: white;

  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

#messageList div.message.fadeout {
  -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 1s; /* Firefox < 16 */
  -ms-animation: fadeout 1s; /* Internet Explorer */
  -o-animation: fadeout 1s; /* Opera < 12.1 */
  animation: fadeout 1s;
}

#messageList div.message.success {
  border-left: 10px solid #095c66;
}

#messageList div.message.warning {
  border-left: 10px solid #ccc000;
}

#messageList div.message.error {
  border-left: 10px solid darkred;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}