
/* needs to have a z-index higher than following filters so the menu stays on top when on small screens*/
.noteFilterColor {
  grid-area: color;
  z-index: 2;
}

.noteFilterVisibility {
  grid-area: visibility;
  z-index: 1;
}
