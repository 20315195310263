section.boxedSection h2 {
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 24px;
  font-family: "freight-sans-pro", sans-serif;
  font-weight: 500;
}

section.boxedSection h3 {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 8px;
  font-family: freight-sans-pro,sans-serif;
  font-weight: 600;
}

section.boxedSection .boxedSection-paper {
  color: #333;
  padding: 8px 16px 16px 16px;
  margin-bottom: 24px;
  position: relative;
  background-color: #f4f4f4;
}

.boxButtons {
  position: absolute;
  top: 4px;
  right: 4px;
}

section.boxedSection div.boxInstructions {
  margin-bottom: 8px;
}

section.boxedSection .disabled {
  opacity: .5;
}

section.boxedSection input {
  margin-right: 10px;
}

section.boxedSection div.boxActions {
  margin-top: 8px;
}
