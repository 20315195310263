#menubarSearchContainer {
  display: flex;
}

.searchScopeControl:before, .searchScopeControl:after {
  border: none !important;
}

#searchSelectedScope {
  padding-left: 8px;
}

@keyframes flash {
  0% {
    background-color: #dfdfdf;
    opacity: 1;
  }
  30% {
    background-color: #ffff00;
  }
  100% {
    background-color: #dfdfdf;
  }
}

.menubarScopeChip {
  animation-name: flash;
  animation-duration: 800ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

#menubarMulticodeChipLoadIcon {
  padding: 0 3px;
}

#menubarMulticodeChipLoadIcon > div {
  width: 18px;
  height: 18px;
}

.menubarSearchButtons {
  margin-left: 8px;
}

.menubarSearchButton {
  margin-top: 3px;
  height: 34px;
}