#codeFinderPaper {
  height: 100%;
  font-variant-numeric: lining-nums;
}

#codeFinder .moreActions {
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px 24px 20px;
}

#codeFinder .heading {
  padding: 24px 8px 16px;
  font-size: 1.5rem;
  font-family: "freight-sans-pro", sans-serif;
  font-weight: 500;
  line-height: 1.6;
}

#codeFinder .subHeading {
  font-size: 1.17em;
  font-family: "freight-sans-pro", sans-serif;
  font-weight: 500;
  line-height: 1.9;
}

#codeFinderContent {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0 16px 0 16px;
  padding: 0 24px 0 24px;
  position: relative;
}

#codeFinderLeftColumn {
  flex: 0 0 16%;
  min-width: 260px;
  margin-right: 16px;
}

#codeFinder .card {
  overflow: visible;
}

#codeFinder .card > div {
  padding: 16px;
}

#codeFinder .formControl + .formControl {
  margin-top: 16px;
}

#codeFinder .formControl {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

#codeFinder .inputClear {
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
}

#codeFinder .selectField, #codeFinder .textField {
  width: 100%;
}

#codeFinderPopulationContent, #codeFinderPopulationInput, .codeFinderSliderField {
  width: 98%;
  min-width: 192px;
}

#codeFinder .textField input {
  padding: 10px 0 7px;
}

#codeFinder .dialogSection + .dialogSection {
  margin-top: 16px;
}

#codeFinderZipCodeControl {
  width: 100%;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

#codeFinderZipCodeControl .selectField {
  width: 50%;
}

#codeFinderZipCodeControl .textField {
  width: 35%;
}

#codeFinderZipCodeControl #codeFinderOfLabel {
  margin: 26px 12px 7px 8px;
  color: rgba(0, 0, 0, 0.54);
}

#codeFinderWithinLabel {
  align-items: flex-end;
  margin-bottom: -16px;
}

#codeFinderPopulationControl.formControl {
  align-items: flex-end;
}

#codeFinderPopulationLabel {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  position: relative;
  top: 0;
  left: 0;
  margin-top: 16px;
}

#codeFinderPopulationInput {
  margin: 8px 0 8px 0;
}

#codeFinderRightColumn {
  flex: 1 1 83%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#codeFinderSelectCodesContent {
  min-height: 512px;
}

#codeFinderSelectedCodeContainer {
  -ms-flex: 0 1 30%;
  flex: 0 1 30%;
}

#codeFinderSelectedCodesLabel {
  padding-right: 8px;
  line-height: 1.9;
}

#customerTableLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 18px 16px 18px;
}

#codeFinder .clearButton svg {
  fill: rgba(0, 0, 0, 0.54);
}

#codeFinder .clearButton:hover svg {
  fill: rgba(0, 0, 0, 0.87);
}

#codeFinder .removeIcon svg {
  fill: rgba(0, 0, 0, 0.34);
}

#codeFinder .removeIcon:hover svg {
  fill: rgba(0, 0, 0, 0.87);
}

#codeFinder .cellLink {
  font-weight: 600;
  color: #333;
  text-decoration: none;
}

#codeFinder .cellLink:hover {
  text-decoration: underline;
}

#codeFinderSelectCodes .spinnerContainer {
  display: block;
  height: 100%;
  width: 45px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10%;
  padding-right: 10%;
}

#codeFinderSearchField {
  height: 26px;
  padding: 8px;
  font-variant-numeric: proportional-nums lining-nums;
}

#codeFinder .searchFieldContainer {
  width: 100%;
}

#codeFinderActions {
  display: block;
  margin: 8px 24px 24px 24px;
}

#codeFinderActions > .heading {
  margin: 0;
}

#codeFinderActions > .content {
  display: flex;
}

#codeFinder #customerTable .row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

#codeFinder #customerTableData {
  height: 424px;
}

#codeFinder #customerTableData > div > div {
  overflow-y: scroll !important;
}

#codeFinder #customerTableData .row {
  height: 48px;
}

#codeFinder #customerTableHeader .row {
  height: 36px;
  width: calc(100% - 17px);
  align-items: flex-end;
}

#codeFinder #customerTable .cell {
  flex: 0 0 10%;
  font-size: 1rem;
  padding: 4px 8px 4px 16px;
}

#codeFinder #customerTable .selectCell.cell {
  flex: 0 0 40px;
}

#codeFinder #customerTable .nameCell.cell {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#codeFinder #customerTableHeader .cell {
  display: flex;
  font-size: .9rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}

#codeFinder #customerTableHeader .cell:not(.selectCell):hover {
  color: rgba(0, 0, 0, 0.87);
}

#codeFinder #customerTableHeader .cell.selectCell:hover {
  cursor: default;
}

#codeFinder #customerTableHeader .cellLabel {
  padding-top: 5px;
}

#codeFinder #customerTableHeader .selectCell.cell {
  padding: 4px 2px 4px 20px;
}

#codeFinder .Select {
  padding-top: 16px;
}

#codeFinder .Select-control {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0 0 1px 0;
  border-color: rgba(0, 0, 0, 0.42);
  outline: 0;
  box-shadow: none;
  cursor: text;
  overflow: visible;
  background-color: transparent;
  padding: 3px 0 1px 0;
  z-index: 1;
}

#codeFinder .Select.is-focused .Select-control, #codeFinder .Select-control:hover {
  padding-bottom: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

#codeFinder .Select--multi .Select-value {
  color: rgba(0, 0, 0, 0.87);
  height: 28px;
  cursor: default;
  border: none;
  display: inline-flex;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  align-items: center;
  border-radius: 16px;
  background-color: #e0e0e0;
  margin: 2px;
  padding: 0 2px 0 0;
}

#codeFinder .Select--multi .Select-value-icon {
  font-size: 24px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px 0 12px 10px;
  border: none;
}

#codeFinder .Select--multi .Select-value-icon:hover {
  background-color: transparent;
  color: black;
}

#codeFinder .Select--multi .Select-value-label {
  color: rgba(0, 0, 0, 0.87);
  border-radius: 16px;
  padding: 0 12px 0 6px;
  font-size: 0.9285714285714285rem;
  font-family: "freight-sans-pro", sans-serif;
}

#codeFinder .Select--multi .Select-input {
  margin-left: 0;
}

#codeFinder .Select-placeholder {
  display: none;
}

#codeFinder .Select + .Select-label {
  padding: 0;
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  -o-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.1428571428571428rem;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 0;
}

#codeFinder .Select.is-focused + .Select-label, #codeFinder .Select.has-value + .Select-label {
  transform: translate(-10%, -22px) scale(0.75);
}

#codeFinder .Select.is-focused + .Select-label {
  color: rgba(0, 0, 0, 0.87);
}

#codeFinder .Select-menu-outer {
  z-index: 2;
  border-top: 1px solid rgba(0, 0, 0, 0.42);
}

#codeFinder .Select-option {
  min-height: 32px;
}

#codeFinder .Select-option:hover, #codeFinder .Select-option.is-focused {
  background-color: rgba(0, 0, 0, 0.07);
  border: none;
  outline: 0;
  box-shadow: none;
}

#codeFinder .Select--single .Select-value, #codeFinder .Select--single .Select-input {
  padding-left: 0;
}

#codeFinder .Select--single .Select-value-label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.1428571428571428rem;
  font-family: "freight-sans-pro", sans-serif;
}

@media (max-width: 995px) {
  #codeFinderContent {
    display: block;
  }

  #codeFinderLeftColumn {
    margin-bottom: 16px;
    width: 100%;
  }
}